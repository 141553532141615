window.$ = require('jquery');
window.tippy = require('tippy.js');
require('jquery-validation');
require('jsrender')($);
require('jquery-match-height');

// Requires the country field to have the data attribute data-behavior="country-select"
$.validator.addMethod("postalCodeBasedOnCountry", function(value, element) {
    let countryIso = $("[data-behavior~=country-select]").val()
    if([null, ""].indexOf(countryIso) != -1) return;

    let canada_regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    let us_regex = /^\d{5}(?:[-\s]\d{4})?$/;
    let au_regex = /^\d{4}$/;
    let in_regex = /^\d{6}$/;
    let gb_regex = /^(GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4})$/;
    let ng_regex = /^\d{6}$/;

    if(countryIso ==='AE') return true;

    if(countryIso ==='CA' && canada_regex.exec(value)) return true;

    if(countryIso === 'US' && us_regex.exec(value)) return true;

    if ((countryIso === 'AU' || countryIso === 'NZ') && au_regex.exec(value)) return true;

    if ((countryIso === 'IN') && in_regex.exec(value)) return true;

    if ((countryIso === 'GB') && gb_regex.exec(value)) return true;

    if ((countryIso === 'NG') && (!value || ( value.length > 1 && ng_regex.exec(value)) )) return true;

    return false;
  },
  function() {
    let countryIso = $("[data-behavior~=country-select]").val()
    if([null, ""].indexOf(countryIso) != -1) return;

    if (countryIso == "US") {
      return "must be a valid US postal code";
    } else if (countryIso == "CA") {
      return "must be a valid Canada postal code";
    } else if (countryIso == "NZ") {
      return "must be a valid New Zealand postal code";
    } else if (countryIso == 'AU' || countryIso == 'NZ') {
      return "must be a valid Australian postal code";
    } else if (countryIso == "IN") {
      return "must be a valid Indian postal code";
    } else if (countryIso == "GB") {
      return "must be a valid postal code";
    } else if (countryIso == "NG") {
      return "must be a valid postal code";
    }
  }
);


/********************
******* PAGE-1 ******
*********************/


window.BusinessRepInputView = class BusinessRepInputView {
  constructor(formRef) {
    this.$form = $(formRef);
    this.$submitBtn = this.$form.find(formRef + '-submit');
  }


  init() {
    this.$businessTypeInput = $("[data-behavior~=business-type-input]");
    this.$businessTypeOptions = $("[data-behavior~=business-type-option]");
    this.$businessTypeOptions.on("click", this.onClickBusinessType.bind(this));

    let validationRules = {
      "business_rep[name]": {
        required: true,
        maxlength: 40
      },

      "business_rep[phone]": {
        required: true,
        minlength: 9
      },

      "business_rep[email]": {
        required: true,
        email: true
      }
    }

    let errorMessages = {
      "business_rep[name]": {
        required: "required",
        minlength: "required",
        maxlength: "your first name is that long?"
      },

      "business_rep[phone]": {
        required: "required",
        digits: "must be a valid phone number",
        minlength: "must be a valid phone number",
        maxlength: "must be a valid phone number"
      },

      "business_rep[email]": {
        required: "required",
        email: "must be valid"
      }
    }

    this.$form.validate({
      rules: validationRules,
      messages: errorMessages,
      onsubmit: true,
      errorPlacement: this.errorPlacement,
      errorElement: "span",
      errorClass: "cross-alert alert-call",

      // dummify this because default adds the errorClass to input elements
      highlight: function(element, errorClass) {}
    });

    this.$form.submit(function (event) {
      if ($("div[data-callback='captchaSubmit']").length != 0) {
        event.preventDefault();
        grecaptcha.reset();
        grecaptcha.execute();
      }
    });
  }

  toggleSubmitBtnState() {
    this.$submitBtn.prop('disabled', function(i, old) {
      let $this = $(this),
          html = "";

      if (old) { html = 'Check status now'; }
      else { html = 'Please wait...'; }

      $this.html(html);
      return !old;
    });

  }

  onClickBusinessType(event) {
    let $element = $(event.delegateTarget);
    let value = $element.data("value");

    // remove on-select class from all options
    this.$businessTypeOptions.removeClass("on-select");

    // add it to the selected option
    $element.addClass("on-select");

    // Set value in hidden input field
    this.$businessTypeInput.val(value);
  }


  errorPlacement(error, element) {
    let $errorMsg = element.parent();
    $errorMsg.append(error);
  }
}


/********************
******* PAGE-2 ******
*********************/


window.BusinessInputView = class BusinessInputView {
  constructor(formRef) {
    this.$form = $(formRef);
    this.$submitBtn = this.$form.find(formRef + '-submit');
    this.states = {
      "US": [
        'AL', 'AK', 'AS', 'AZ', 'AR',
        'CA', 'CO', 'CT', 'DE', 'DC',
        'FM', 'FL', 'GA', 'GU', 'HI',
        'ID', 'IL', 'IN', 'IA', 'KS',
        'KY', 'LA', 'ME', 'MH', 'MD',
        'MA', 'MI', 'MN', 'MS', 'MO',
        'MT', 'NE', 'NV', 'NH', 'NJ',
        'NM', 'NY', 'NC', 'ND', 'MP',
        'OH', 'OK', 'OR', 'PW', 'PA',
        'PR', 'RI', 'SC', 'SD', 'TN',
        'TX', 'UT', 'VT', 'VI', 'VA',
        'WA', 'WV', 'WI', 'WY'
      ],

      "CA": [
        'AB', 'BC', 'MB', 'NB', 'NL',
        'NS', 'ON', 'PE', 'QC', 'SK',
        'NT', 'NU', 'YT'
      ]
    }
  }


  init() {
    this.$countrySelect = $("[data-behavior~=country-select]");
    this.$countrySelect.on("change", this.onCountryChange.bind(this, false));

    // If country is selected on page load, then load states
    this.onCountryChange(true);

    let validationRules = {
      "scan_query[name]": {
        required: true,
        minlength: 3,
        maxlength: 200
      },

      "scan_query[street]": {
        required: true,
        minlength: 2,
        maxlength: 250
      },

      "scan_query[city]": {
        required: true,
        minlength: 2,
        maxlength: 150
      },

      "scan_query[country]": "required",
      "scan_query[state]": "required",

      "scan_query[phone]": {
        required: true,
        minlength: function(){
          if ($("[data-behavior~=country-select]").val() == "NG"){
            return 5
          }
          if ($("[data-behavior~=country-select]").val() == "NZ" || $("[data-behavior~=country-select]").val() == "AE"){
            return 8
          }
          return 9;
        },
      },

      "scan_query[postal_code]": {
        required:  function(){
          if ($("[data-behavior~=country-select]").val() == "NG" || $("[data-behavior~=country-select]").val() == "AE"){
            return false
          }
          return true;
        },
        postalCodeBasedOnCountry: true
      }
    }

    let errorMessages = {
      "scan_query[name]": {
        required: "required",
        minlength: "required",
        maxlength: "too long"
      },

      "scan_query[street]": {
        required: "required",
        minlength: "must be valid",
        maxlength: "too long"
      },

      "scan_query[city]": {
        required: "required",
        minlength: "must be valid",
        maxlength: "too long"
      },

      "scan_query[country]": "required",
      "scan_query[state]": {
        "required": "required"
      },

      "scan_query[phone]": {
        required: "required",
        digits: "must be a valid phone number",
        minlength: "must be a valid phone number",
        maxlength: "must be a valid phone number"
      },

      "scan_query[postal_code]": {
        "required": function(){
          if ($("[data-behavior~=country-select]").val() == "NG" || $("[data-behavior~=country-select]").val() == "AE"){
            return false
          }
          return "required";
        }
      }
    }

    this.$form.validate({
      rules: validationRules,
      messages: errorMessages,
      onsubmit: true,
      errorPlacement: this.errorPlacement,
      errorElement: "span",
      errorClass: "cross-alert alert-call",

      // dummify this because default adds the errorClass to input elements
      highlight: function(element, errorClass) {}
    });

    this.$form.submit(function (event) {
      if (($("div[data-callback='captchaSubmit']").length != 0) && $(this).valid()){
        event.preventDefault();
        grecaptcha.reset();
        grecaptcha.execute();
      }
    });
  }

  toggleSubmitBtnState() {
    this.$submitBtn.prop('disabled', function(i, old) {
      let $this = $(this),
          html = "";

      if (old) { html = 'Check status now'; }
      else { html = 'Please wait...'; }

      $this.html(html);
      return !old;
    });
  }


  errorPlacement(error, element) {
    let $errorMsg = element.parent();
    $errorMsg.append(error);
  }


  onCountryChange(pageLoad) {
    let countryIso = this.$countrySelect.val(),
        $stateSelect = $("[data-behavior~=state-select]"),
        $stateCloneSelect = $("#state-select-dupe"),
        $countryCodeLabel = $("#country-code");

    if(countryIso === null || countryIso.length < 2){
      return;
    }

    if (countryIso == "GB"){
      $("#stat-select-div").hide();
      $("#city-select-div").addClass("col-sm-12").removeClass("col-sm-6");
    }else{
      $("#stat-select-div").show();
      $("#city-select-div").addClass("col-sm-6").removeClass("col-sm-12");
    }

    if (countryIso == "AE"){
      $("#zip-code-field").hide();
      $("#phone-number-field").addClass("col-sm-12").removeClass("col-sm-6");
    }else{
      $("#zip-code-field").show();
      $("#phone-number-field").addClass("col-sm-6").removeClass("col-sm-12");
    }

    let countryCode = this.$countrySelect.children('option:selected').data('country-code');
    $countryCodeLabel.html("+"+countryCode);
    $stateSelect.attr('disabled', false);
    // Reset the selected option since country changed
    $stateSelect.children().not("option.default").remove();
    let options = $stateCloneSelect.children("option."+countryIso).clone();
    $stateSelect.append(options);
  }
}


/********************
******* PAGE-3 ******
*********************/


window.BusinessListingsView = class BusinessListingsView {
  constructor(scanId, elementRefs) {
    this.pollInterval = 3000,
    this.scanBenchmarkLabel = "Total search time";
    this.totalSiteCount = this.getStat("total-count");

    this.scanId = scanId;
    this.siteResultTemplate = $.templates(elementRefs["siteResultTmpl"]);
    this.noResultTemplate = $.templates(elementRefs["noResultTmpl"]);
    this.liveLinkTemplate = $.templates(elementRefs["liveLinkTmpl"]);
    this.reviewResultTemplate = $.templates(elementRefs["reviewResultTmpl"]);

    this.listingPollCompleted = false;
    this.redrawDonut = true;
  }


  init(display_reviews) {
    console.time(this.scanBenchmarkLabel);
    this.matchHeight();
    this.setUpToolTip();
    this.display_reviews = display_reviews;
    $(window).resize(this.matchHeight.bind(this));

    if (($(".poll").length > 0) || this.display_reviews){
      this.schedulePoll();
      this.showProgressBar();
      if(!($(".poll").length > 0)){
        this.stopListingsPoll();
      }
    }else{
      this.stopListingsPoll();
    }

    $("#listings-button").click(this.showListingScan.bind(this));
    $("#reviews-button").click(this.showReputationScan.bind(this));
  }

  setUpToolTip(sourceName = null){
    if ($("#show_details").val().length == 0 || $("#show_details").val() == "false" ){
      console.log("skipping");
      return
    }
    var element = '.white-tooltip.failer-tooltip'
    if (sourceName != null) {
      element = $("div[data-source-name='"+sourceName+"'] .white-tooltip")[0]
    }

    tippy(element, {
      content(reference) {
        let content = $(reference).find('.tooltip-content').html()
        return content
      },

      theme: "light",
      placement: "right",
      interactive: true
    })

  }

  showListingScan(){
    $("#listings-button").addClass('selected');
    $("#reviews-button").removeClass('selected');
    $("#listings-div").removeClass('hide');
    $("#reviews-div").addClass('hide');
    if (this.listingPollCompleted){
      this.showDonutChart();
    }
  }

  showReputationScan(){
    $("#reviews-button").addClass('selected');
    $("#listings-button").removeClass('selected');
    $("#listings-div").addClass('hide');
    $("#reviews-div").removeClass('hide');
  }

  showProgressBar() {
    $("#canvas-holder")
    .append("<div id=\"progress-bar\" />");

    var ProgressBar = require('progressbar.js')
    this.progress_bar = new ProgressBar.Circle('#progress-bar', {
      color: '#0e90d4',
      trailColor: '#a0d1eb',
      trailWidth: 1,
      duration: 2000,
      easing: 'easeOut',
      strokeWidth: 14,
      from: {color: '#0e90d4', a:0},
      to: {color: '#a0d1eb', a:1},
      // Set default step function for all animate calls
      step: function(state, circle) {
        circle.path.setAttribute('stroke', state.color);
      }
    });
  }

  showDonutChart(){
    if (this.redrawDonut && $("#canvas-holder").is(":visible")){
      let notFound = this.getStat("not-found-count")
      let accurate = this.getStat("accurate-count");
      let inaccurate = this.getStat("inaccurate-count");

      $("#canvas-holder")
        .empty()
        .append("<canvas id=\"chart-area\" width=\"215\" height=\"215\" />");

      var Chart = require('chart.js');

      var doughnutData = [{
          value: notFound,
          color: "#a5a5a5",
          highlight: "#cbcaca",
          label: "Not Found"
      }, {
          value: inaccurate,
          color: "#d90000",
          highlight: "#f74d4d",
          label: "Inaccurate Sites"
      }, {
          value: accurate,
          color: "#50c755",
          highlight: "#7bf680",
          label: "Accurate Sites"
      }];

      let canvasContext = document.getElementById("chart-area").getContext("2d");
      let chart = new Chart(canvasContext).Doughnut(doughnutData, {
          responsive: false,
          segmentStrokeWidth : 4,
          percentageInnerCutout: 60,
          showTooltips: false
      });

      let errorRate = ((notFound + inaccurate)/(notFound + accurate + inaccurate)) * 100;

      let $percentage = $("<div/>").addClass("percentage");
      $percentage.append(`${errorRate.toFixed(1)}% <span>error rate</span>`);
      $("#canvas-holder").append($percentage);

      this.changeBackground();
      this.redrawDonut = false;
      let fixNowUrl = $("[data-container~=fix-now-url]").attr("href")
      $("div.chart-details").append(`<span class='metric'>${errorRate.toFixed(1)}% of your listings</span> <span>are showing incorrect information to customers!</span><br/><a href="${fixNowUrl}" class="btn btn-status text-uppercase btn-fix">Fix Now</a>`)
    }
  }

  matchHeight() {
    $('.parent').each(function(e) {
      $(this).find('.child').matchHeight({byRow: true});
    });

    let getToltalHeight = $(".chart-analyse").height();
    let getTitleHeight = $(".current-list h3").height();
    let targetHeight = getToltalHeight - getTitleHeight;
    $(".current-rate").css({"height": targetHeight / 2});
  }


  schedulePoll() {
    let pollCount = this.display_reviews ? 30 : 20;
    this.pollIntervalRef = setInterval(this.pollListings.bind(this), this.pollInterval);
    setTimeout(this.stopPoll.bind(this), this.pollInterval * pollCount);
  }


  pollListings() {
    console.group("Poll listings"); // this group is ended in pollCallback

    $.get(this.pollUrl(), this.pollCallback.bind(this));

    if ($(".poll").length > 0){
      this.animateProgressBar()
    }

    console.groupEnd();
  }

  pollCallback(data) {
    // * Get sites with poll class
    // * Replace data from poll if result is available && remove poll class in DOM
    // * Replace data from poll if false && remove poll class in DOM
    // * If no more poll elements, clear poll interval
    const site_results = data.site_results;
    const review_results = data.review_results;

    const site_wise_review_results = data.site_wise_review_results;

    $(".poll").each(function(index, element) {
      let sourceName = $(element).data("source-name");

      // Search in progress; Ignore
      if(site_results[sourceName] === null) return;

      $(element).children("[data-container~=details]").remove();
      $(element).children("[data-container~=status]").remove();
      $(element).removeClass("poll");

      if(site_results[sourceName] === false) {
        // No result
        this.setNoResult(element);
        this.incrementStat("not-found");
      } else {
        // Result present
        let siteResult = site_results[sourceName];
        this.setSearchResult(element, siteResult);
        this.setUpToolTip(sourceName)
      }
    }.bind(this));
    if (this.display_reviews){
      if (!$.isEmptyObject(review_results) && $("#recent-reviews .spinner").is(":visible")){
        $("#recent-reviews .spinner").hide();
        $("#recent-reviews").removeClass("no-reviews");
        $.each(review_results, function(i, result) {
          if (!$("div.review-container[data-id='"+result['hash_id']+"']").length){
            this.setReviewResult(result);
          }
        }.bind(this));
      }
      if (!$.isEmptyObject(site_wise_review_results)){
        $.each(site_wise_review_results, function(site, val) {
          $.each(val, function(key, value){
            this.setSiteReviewResult(site, key, value);
          }.bind(this));
        }.bind(this));
      }
    }
    if($(".poll").length === 0 && !this.listingPollCompleted) {
      this.stopListingsPoll();
    }
  }

  setSiteReviewResult(site, key, value){
    if (value){
      $("div[data-site='"+site+"']").find("div[data-type='"+key+"']").html(value);
    }
  }

  setReviewResult(result){
   let full_rating = Array.apply(null, Array(result.rating/2)).map(function (_, i) {return i;});
   let half_rating = Array.apply(null, Array(result.rating%2)).map(function (_, i) {return i;});
   result['full_rating'] = full_rating;
   result['half_rating'] = half_rating;
   let html = this.reviewResultTemplate.render(result);
   $("#recent-reviews").append(html);
  }

  setSearchResult(element, result) {
    let templateData = result["_source"]

    if(templateData["accurate"]) {
      templateData["accuracy_status"] = "accurate";
    } else {
      templateData["accuracy_status"] = "inaccurate";
    }

    let accuracy_details = templateData["accuracy_details"]
    templateData["accuracy_status_name"] = accuracy_details["name"]["accurate"] ? "accurate":"inaccurate";
    templateData["accuracy_status_address"] = accuracy_details["address"]["accurate"] ? "accurate":"inaccurate";
    templateData["accuracy_status_phone"] = accuracy_details["phone"]["accurate"] ? "accurate":"inaccurate";

    templateData["accuracy_message_name"] = accuracy_details["name"]["message"];
    templateData["accuracy_message_address"] = accuracy_details["address"]["message"];
    templateData["accuracy_message_phone"] = accuracy_details["phone"]["message"];

    this.incrementStat(templateData["accuracy_status"]);
    this.animateProgressBar()


    let html = this.siteResultTemplate.render(templateData);
    $(element).append(html);
    // Extra only because there's a live link for this case
    // Only live_link is used. But just pass everything. It's fine
    let liveLinkHtml = this.liveLinkTemplate.render(templateData);
    $(element).children("[data-container~=site-info]").append(liveLinkHtml);
  }

  changeBackground(){
    document.getElementsByClassName('chart-analyse')[0].style.backgroundColor = "#fff1f1";
    document.getElementsByClassName('chart-analyse')[0].style.borderColor = "#ffd0d0";
    document.getElementsByClassName('percentage')[0].style.boxShadow = "0 0 13px rgba(116, 116, 116, 1) inset";
  }

  setNoResult(element) {
    let html = this.noResultTemplate.render();
    $(element).append(html);
    let sourceName = $(element).data("source-name");
    this.setUpToolTip(sourceName)
  }


  stopPoll() {
    if(this.pollIntervalRef === null) return;

    clearInterval(this.pollIntervalRef);

    console.timeEnd(this.scanBenchmarkLabel);

    this.pollIntervalRef = null;
    this.stopListingsPoll();
    this.stopReviewsPoll();
  }

  stopListingsPoll(){
    if(!this.listingPollCompleted){
      let timedoutSites = $(".poll").length;
      this.incrementStat("not-found", timedoutSites);
      $(".poll").each(function(index, element) {
        $(element).children("[data-container~=details]").remove();
        $(element).children("[data-container~=status]").remove();
        $(element).removeClass("poll");

        this.setNoResult(element);
      }.bind(this));
      this.showDonutChart();
      this.listingPollCompleted = true;
    }
  }

  stopReviewsPoll(){
    $("#review-summary .spinner").each(function(spinner){
      $(this).parent().html("N/A");
    });

    $(".no-reviews").html("No reviews available.");
  }

  // Valid stat names: accurate, inaccurate, not-found
  // These directly translate to an element's data attribute
  incrementStat(statName, incrementBy=1) {
    let containerName = `${statName}-count`;
    let $container = $(`[data-container~=${containerName}]`);
    let statValue = parseInt($container.text(), 10) + incrementBy;
    $container.html(statValue);

    // Update found count if stat is accurate or inaccurate count
    if(statName !== "not-found") {
      let otherContainerName = (statName === "accurate") ? "inaccurate-count" : "accurate-count";
      let $otherContainer = $(`[data-container~=${otherContainerName}]`);
      let otherStat = parseInt($otherContainer.text(), 10);
      $(`[data-container~=found-count]`).html(statValue + otherStat);
    }
  }

  animateProgressBar() {

    let foundCount = parseInt($(`[data-container~='inaccurate-count']`).text(), 10) + parseInt($(`[data-container~='accurate-count']`).text(), 10)
    let notFoundCount = parseInt($(`[data-container~='not-found-count']`).text(), 10)
    let totalScanned = foundCount + notFoundCount;
    this.progress_bar.animate(totalScanned/this.totalSiteCount);

    $("div.percentage").empty();
    $("div.percentage").append(`<span class=\"pbar-text1\">Scan in progress</span><span class=\"pbar-count\"> ${totalScanned} of ${this.totalSiteCount}</span><span class=\"pbar-text2\">listings scanned</span>`);
  }

  getStat(containerName) {
    let $container = $(`[data-container~=${containerName}]`);
    return parseInt($container.text(), 10)
  }


  pollUrl() {
    return `/search/${this.scanId}/listings.json`
  }


}
